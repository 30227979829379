import React from 'react'
import {
  PageWrap,
  PageRow,
  PageHead,
  PageCopy,
  PageExpand,
} from '../components/global'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from 'reflexbox/styled-components'
import Img from 'gatsby-image'

export default ({ data }) => {
  let sdImage
  if (data.sdImage) {
    sdImage = <Img fluid={data.sdImage.childImageSharp.fluid} alt="" />
  }
  let jdImage
  if (data.jdImage) {
    jdImage = <Img fluid={data.jdImage.childImageSharp.fluid} alt="" />
  }

  const philosophyContent = (
    <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
      <Box
        ml={[0, `${(2 / 10) * 100}%`]}
        mr={[0, `${(2 / 10) * 100}%`]}
        width={[1, `${(6 / 10) * 100}%`]}
      >
        <PageCopy>
          <p>
            As builders, we believe that our approach needs to go beyond good
            workmanship, it can shape how it feels to create a home, something a
            client may only do once in their lifetime. We are dedicated to
            having a positive impact on the journey from architectural vision to
            clients aspirations.
            <br />
            <br />
            As a company, it is essential that we continuously focus on process
            that facilitates true collaboration, and building excellence. For
            this reason we remain intentionally lean, only taking on a limited
            number of projects to ensure we can maintain highly personalised and
            positive experiences for the project team, client, and ourselves.{' '}
            <br />
            <br />
            Valuing good people and long lasting relationships is the measure
            for everything we do – it sets the benchmark for how we act, hire
            and deliver.
          </p>
        </PageCopy>
      </Box>
    </Flex>
  )

  const leadershipContent = (
    <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
      <Box
        ml={[0, `${(2 / 10) * 100}%`]}
        mr={[0, `${(2 / 10) * 100}%`]}
        width={[1, `${(6 / 10) * 100}%`]}
      >
        <PageCopy>
          <p>
            Director Sam Dixon, a third generation builder, joined the company
            in 2008, however his passion for craftsmanship started much earlier.
            Growing up around the worksite he learnt from a young age what is
            possible with the right balance of people, skills and technical
            expertise. <br />
            <br />
            Working alongside his father, John H. Dixon, Sam stepped up to
            director in 2013, and continues to drive the business forward by
            surrounding himself with good people who share his values and a care
            for the details that make the difference between good and great.
          </p>
        </PageCopy>
      </Box>
    </Flex>
    // <PageGallery data={LEADERSHIP_IMAGES} allImages={data.images} />
  )

  return (
    <PageWrap>
      <SEO
        title="Company"
        description="As builders, we believe that our approach needs to go beyond
                good workmanship, it can shape how it feels to create a home,
                something a client may only do once in their lifetime."
      />
      <PageRow>
        <PageHead>
          The
          <br />
          Company
        </PageHead>
        <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, `${(6 / 10) * 100}%`]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <PageCopy>
              <p>
                As builders, we believe that our approach needs to go beyond
                good workmanship, it can shape how it feels to create a home,
                something a client may only do once in their lifetime.
              </p>
            </PageCopy>
          </Box>
        </Flex>
      </PageRow>

      <PageRow>
        <PageExpand
          title="Philosophy"
          content={philosophyContent}
          isOpen={false}
        />
      </PageRow>

      <PageRow>
        <PageExpand
          title="Leadership"
          content={leadershipContent}
          isOpen={false}
        />
      </PageRow>
      <PageRow>
        <Flex flexWrap={[`wrap`]} pb={[3, 0]}>
          <Box width={[1 / 2]} px={[2, 3]} my={[2, 3]}>
            {sdImage}
          </Box>
          <Box width={[1 / 2]} px={[2, 3]} my={[2, 3]}>
            {jdImage}
          </Box>
        </Flex>
      </PageRow>
    </PageWrap>
  )
}

export const query = graphql`
  query {
    sdImage: file(
      relativePath: {
        eq: "sections/jhdixon-post-2019-12-20-photo-woodrow-8830.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jdImage: file(
      relativePath: {
        eq: "sections/jhdixon-post-2019-12-20-photo-woodrow-8914.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
